<template>
  <div class="login">
    <div class="title">小牛哥先锋骑手后台管理系统</div>
    <!-- 登陆 -->
    <div class="login_form">
      <el-form ref="Form" :model="Form" :rules="FormRules" label-width="150px">
        <el-row>
          <el-col :span="18">
            <el-form-item prop="userName">
              <el-input
                placeholder="账号"
                prefix-icon="el-icon-user"
                v-model="Form.userName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item prop="passWord">
              <el-input
                placeholder="密码"
                prefix-icon="el-icon-key"
                v-model="Form.passWord"
                show-password
                @keyup.enter.native="onSubmit('Form')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item>
              <div class="check">
                <span @click="handlePass">修改密码</span>
                <!-- <div class="pass">忘记密码</div> -->
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item class="btn">
              <el-button type="primary" @click="onSubmit('Form')"
                >登陆</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户手机" prop="phone">
          <el-input v-model.trim="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="passWord">
          <el-input v-model.trim="form.passWord" show-password></el-input>
        </el-form-item>
        <el-form-item label="确定密码" prop="passsords">
          <el-input v-model.trim="form.passsords" show-password></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="checkCode">
          <div class="code">
            <el-input v-model.trim="form.checkCode"></el-input>
            <el-button v-show="show" type="primary" @click="getCode"
              >获取验证码</el-button
            >
            <el-button v-show="!show" type="primary" disabled
              >{{ count }}s 重新获取验证码</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.passWord) {
        callback(new Error("两次输入密码不一致！"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        checkCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        passWord: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          // { validator: validatePass,  trigger: "blur" },
        ],
        passsords: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: validatePass1, trigger: "blur" },
        ],
      },
      Form: {
        userName: "",
        passWord: "",
      },
      checked: false,
      dialogVisible: false,
      form: {
        phone: null,
        checkCode: "",
        passWord: "",
        passsords: "",
      },
      show: true,
      count: "",
      timer: null,
      pass: false,
    };
  },
  mounted() {
    if (sessionStorage.getItem("token")) {
      this.$router.push("/Layout");
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$post("/oauth/backedLogin", this.Form);
          console.log(res);
          if (res.flag) {
            sessionStorage.setItem("token", res.data);
            sessionStorage.setItem("userName", this.Form.userName);
            this.$router.push({ name: "Layout" });
          } else {
            this.$message.error(res.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getPhone() {
      const res = await this.$get("/oauth/phoneCode", {
        phone: this.form.phone,
      });
      console.log(res);
      if (res.flag) {
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    },
    getCode() {
      if (this.form.phone) {
        this.getPhone();
        if (!this.timer) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } else {
        this.$message.error("请输入电话号码");
      }
    },
    handlePass() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.form = {
            phone: null,
            checkCode: "",
            passWord: "",
            passsords: "",
          };
          done();
        })
        .catch((_) => {});
    },
    handleSubmit(form) {
      delete this.form.passsords
      this.$refs[form].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          const res = await this.$post("/oauth/changePwd", this.form);
          console.log(res);
          if (res.flag) {
            this.$message.success(res.message);
            this.form = {
              phone: null,
              checkCode: "",
              passWord: "",
              passsords: "",
            };
            this.dialogVisible = false;
          } else {
            this.$message.error(res.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  computed: {
    FormRules() {
      return {
        userName: [
          { required: true, message: "请输入账号号码", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/bg.png);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 40px;
  }
  .login_form {
    width: 707px;
    height: 467px;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 9px 28px 4px rgba(192, 215, 239, 0.49);
    .title {
      font-size: 10px;
    }
    .check {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #3eb4fa;
      cursor: pointer;
    }
    .pass {
      color: #3eb4fa;
      font-size: 14px;
    }
    .btn .el-button {
      width: 100%;
    }
  }
}
.code {
  display: flex;
  button {
    width: 150px;
  }
}
</style>